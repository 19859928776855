export {containsString, containsAllString}

function containsString(s: string, sub: string): Boolean {
    return s.includes(sub)
}

function containsAllString(s: string, input: string): Boolean {
    let result = true
    let words: string[] = input.split(/(\s+)/).filter( function(e) { return e.trim().length > 0; } )
    for (let word of words) {
        if (!containsString(s, word)) {
            result = false
            break
        }
    }
    return result
}
