import React, {SyntheticEvent, useEffect, useState} from 'react';
import './App.css';
import {containsAllString} from "./Commons";
import axios from "axios";

function App() {

    const resturl = process.env.REACT_APP_API_URL

    interface CommonValue {
        bookId: string
        author: string
        title: string
        publisher: string
    }

    interface IndexedValues {
        index: string
        commonValues: CommonValue[],
    }

    const initial_data: IndexedValues[] = []
    const maxRows = 500
    const [data, setData] = useState(initial_data)
    const initial_filtered_data: CommonValue[] = []
    const [filteredData, setFilteredData] = useState(initial_filtered_data)

    const inputFindChanged = (e: SyntheticEvent): void => {
        let inputElement = e.target as HTMLInputElement
        let text = inputElement.value.toLowerCase()
        let ntext = text.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
        setFilteredData(
            data
                .filter(iv => containsAllString(iv.index, ntext))
                .flatMap(iv => iv.commonValues)
                .slice(0, maxRows)
        )
        console.log(`filtered by '${ntext}'`);
    }

    useEffect( () => {
        console.log("use effect")
        axios.get(`${resturl}index`)
            .then((response) => {
                setData(response.data)
            })
            .catch((reason) => console.log(`reason: ${reason}`))
            .finally(() => console.log("finally"))
        setFilteredData(
            data
                .flatMap((x) => x.commonValues)
                .slice(0, maxRows)
        )
    }, [] )

    const epubLink = (id: string): string => `${resturl}epub/${id}`

    return (
        <div className="App">
            <header className="App-header">
                <h1>Projekt Gutenberg</h1>
                <input className="inputtextfind" type="text"
                       onKeyUp={inputFindChanged}/>
                <table>
                    <tbody>
                    <tr>
                        <th>Author</th>
                        <th>Titel</th>
                        <th>Herausgeber</th>
                    </tr>
                    {filteredData.map((cv: CommonValue) => {
                        return (
                            <tr key={cv.bookId}>
                                <td className="firstcolumn"><a href={epubLink(cv.bookId)}>{cv.author}</a></td>
                                <td><a href={epubLink(cv.bookId)}>{cv.title}</a></td>
                                <td><a href={epubLink(cv.bookId)}>{cv.publisher}</a></td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </header>
        </div>
    );
}


export default App;
